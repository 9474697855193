import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { G2gUserTileComponent } from '../g2g-user-tile/g2g-user-tile.component';

@Component({
  selector: 'app-g2g-user-list',
  standalone: true,
  imports: [
    G2gUserTileComponent
  ],
  templateUrl: './g2g-user-list.component.html',
  styleUrl: './g2g-user-list.component.scss'
})
export class G2gUserListComponent {
  @Input('totalCount') totalCount = 0;
  @Input('paginatedUsers') paginatedUsers: any[] = [];
}
