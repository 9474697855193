<ul class="player-list">
    @if(totalCount > 0) {
    @for (user of paginatedUsers; track $index) {
    <app-g2g-user-tile [user]="user"></app-g2g-user-tile>
    }
    } @else {
    <div class="no-players w-100 d-flex align-items-center justify-content-center">
        No Users Found
    </div>
    }
</ul>